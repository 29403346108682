import Vue from "vue";
import VueRouter from "vue-router";
import { checkUserStatus } from "@/utils/checkUserStatus";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // examination
    {
      path: "/dashboards/form-examination",
      name: "form-examination",
      component: () => import("@/view/main/dashboards/examination/Form"),
    },
    {
      path: '/dashboards/list-user-examinations',
      name: 'ViewUserExams',
      component: () => import("@/view/main/dashboards/examination/ListDonorParticipant.vue"),
    },
    // MAIN
    {
      path: "/dashboards/analytics",
      name: "dashboards-analytics",
      component: () => import("@/view/main/dashboards/analytics"),
      meta: { roles: ["ADMIN"] },
    },
    {
      path: "/dashboards/stocks",
      name: "dashboards-stocks",
      component: () =>
        import(
          "@/view/main/dashboards/analytics/informationType/Stock/StockDashboard.vue"
        ),
      meta: { roles: ["ADMIN"] },
    },
    {
      path: "/dashboards/finances",
      name: "dashboards-finances",
      component: () =>
        import(
          "@/view/main/dashboards/analytics/informationType/Finance/FinanceDashboard.vue"
        ),
      meta: { roles: ["ADMIN"] },
    },
    // MANAGE USERS
    {
      path: "/dashboards/users",
      name: "manage-user",
      component: () => import("@/view/main/dashboards/manageuser/Users"),
      meta: {
        title: "Manage",
        roles: ["REGISTER", "ALLROLE", "ADMIN"],
      },
    },
    {
      path: "/dashboards/user/add-user",
      name: "manage-add-user",
      component: () =>
        import("@/view/main/dashboards/manageuser/AddUser/AddUser.vue"),
      meta: {
        title: "Manage Add User",
        roles: ["REGISTER", "ALLROLE", "ADMIN"],
      },
    },
    {
      path: "/dashboards/user/detail/:id",
      name: "manage-user-detail",
      component: () => import("@/view/main/dashboards/manageuser/ViewUser"),
      meta: {
        title: "Manage",
        roles: ["REGISTER", "ALLROLE", "ADMIN"],
      },
    },
    {
      path: "/dashboards/user/edit-user/:id",
      name: "manage-user-edit",
      component: () => import("@/view/main/dashboards/manageuser/EditUser"),
      meta: {
        title: "Manage",
        roles: ["REGISTER", "ALLROLE", "ADMIN"],
      },
    },
    //  Reservations
    {
      path: "/dashboards/user/reservation-user/:id",
      name: "reservation",
      component: () =>
        import("@/view/main/dashboards/reservation/view/ReservationUser.vue"),
      meta: {
        title: "Manage",
        roles: ["REGISTER", "ALLROLE", "ADMIN"],
      },
    },
    //  Reservations
    //  BLOODVANS
    {
      path: "/dashboards/bloodvans",
      name: "bloodvans",
      component: () =>
        import("@/view/main/dashboards/managebloodvan/Bloodvans.vue"),
    },
    {
      path: "/dashboards/bloodvan/:id",
      name: "bloodvan",
      component: () =>
        import("@/view/main/dashboards/managebloodvan/ViewBloodvan.vue"),
    },
    {
      path: "/dashboards/add-bloodvan",
      name: "add-bloodvan",
      component: () =>
        import("@/view/main/dashboards/managebloodvan/AddBloodvan.vue"),
    },
    {
      path: "/dashboards/edit-bloodvan/:id",
      name: "edit-bloodvan",
      component: () =>
        import("@/view/main/dashboards/managebloodvan/EditBloodvan.vue"),
    },
    //  QUISIONERS
    {
      path: "/dashboards/quisioners",
      name: "quisioners",
      component: () =>
        import("@/view/main/dashboards/managequisioner/Quisioners.vue"),
    },
    {
      path: "/dashboards/add-quisioner",
      name: "addQuisioner",
      component: () =>
        import("@/view/main/dashboards/managequisioner/AddQuisioner.vue"),
    },
    {
      path: "/dashboards/edit-quisioner",
      name: "editQuisioner",
      component: () =>
        import("@/view/main/dashboards/managequisioner/EditQuisioner.vue"),
    },
    {
      path: "/dashboards/quisioner",
      name: "viewQuisioner",
      component: () =>
        import("@/view/main/dashboards/managequisioner/ViewQuisioner.vue"),
    },
    //  DONATIONS
    {
      path: "/dashboards/donations",
      name: "donations",
      component: () =>
        import("@/view/main/dashboards/registrationdonation/Donations.vue"),
      roles: ["REGISTER", "ALLROLE", "ADMIN"],
    },
    {
      path: "/dashboards/donation/:id",
      name: "donation",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/ViewDonationStatus.vue"
        ),
      roles: ["REGISTER", "ALLROLE", "ADMIN"],
    },
    // DOCTOR
    {
      path: "/dashboards/dokter",
      name: "dokter",
      component: () =>
        import(
          "@/view/main/dashboards/registration_donation/view/dokter/Dokter.vue"
        ),
      meta: { roles: ["DOCTOR", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/dokter/:id",
      name: "dokter-detail",
      component: () =>
        import(
          "@/view/main/dashboards/registration_donation/view/dokter/DokterForm.vue"
        ),
      meta: { roles: ["DOCTOR", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/dokter-registrasi/:id",
      name: "dokter-registrasi",
      component: () =>
        import(
          "@/view/main/dashboards/registration_donation/view/dokter/DokterForm.vue"
        ),
      meta: { roles: ["DOCTOR", "ALLROLE", "ADMIN"] },
    },
    // -----------------------
    {
      path: "/dashboards/doctors",
      name: "doctors",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/views/doctor/ViewDoctor.vue"
        ),
      meta: { roles: ["DOCTOR", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/doctor/:id",
      name: "doctor",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/ViewDocterStatus.vue"
        ),
      meta: { roles: ["DOCTOR", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/doctor-detail/:id",
      name: "doctor-view",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/views/doctor/DetailDoctor.vue"
        ),
      meta: { roles: ["DOCTOR", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/doctor/:id/update",
      name: "donationEditQuestion",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/ViewDoctorQuestionnaire.vue"
        ),
    },
    // AKTIVASI HB
    {
      path: "/dashboards/HB",
      name: "HB",
      component: () =>
        import("@/view/main/dashboards/registration_donation/view/hb/HB.vue"),
      meta: { roles: ["HB", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/HB/:id",
      name: "HB detail",
      component: () =>
        import(
          "@/view/main/dashboards/registration_donation/view/hb/HBForm.vue"
        ),
      meta: { roles: ["HB", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/HB-registrasi/:id",
      name: "HB registrasi",
      component: () =>
        import(
          "@/view/main/dashboards/registration_donation/view/hb/HBForm.vue"
        ),
      meta: { roles: ["HB", "ALLROLE", "ADMIN"] },
    },
    // ----------------
    {
      path: "/dashboards/activasiHB",
      name: "activasiHB",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/views/activasiHB/ViewHB.vue"
        ),
      meta: { roles: ["HB", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/activasiHB-form/:id",
      name: "activasiHB",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/views/activasiHB/ViewHBStatus.vue"
        ),
      meta: { roles: ["HB", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/detail-activasiHB/:id",
      name: "activasiHB",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/views/activasiHB/DetailHB.vue"
        ),
    },
    {
      path: "/dashboards/detail-activasiHB/:id",
      name: "activasiHB",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/views/activasiHB/DetailHB.vue"
        ),
    },
    // AKTIVASI AFTAF
    {
      path: "/dashboards/aftap",
      name: "aftap",
      component: () =>
        import(
          "@/view/main/dashboards/registration_donation/view/aftap/Aftap.vue"
        ),
      meta: { roles: ["AFTAP", "ALLROLE", "ADMIN", "REGISTER"] },
    },
    {
      path: "/dashboards/aftap/:id",
      name: "aftap detail",
      component: () =>
        import(
          "@/view/main/dashboards/registration_donation/view/aftap/AftapForm.vue"
        ),
      meta: { roles: ["AFTAP", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/aftap-registrasi/:id",
      name: "aftap registrasi",
      component: () =>
        import(
          "@/view/main/dashboards/registration_donation/view/aftap/AftapForm.vue"
        ),
      meta: { roles: ["AFTAP", "ALLROLE", "ADMIN"] },
    },
    // ----------------
    {
      path: "/dashboards/activasiaftafs",
      name: "activasiAftafs",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/views/activasiAftaf/ViewAftaf.vue"
        ),
      meta: { roles: ["AFTAP", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/activasiaftaf-form/:id",
      name: "activasiHB",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/views/activasiAftaf/ViewAftafStatus.vue"
        ),
      meta: { roles: ["AFTAP", "ALLROLE", "ADMIN"] },
    },
    {
      path: "/dashboards/detail-activasiaftaf/:id",
      name: "activasiAftap",
      component: () =>
        import(
          "@/view/main/dashboards/registrationdonation/views/activasiAftaf/DetailAftaf.vue"
        ),
    },
    // blood shipments
    {
      path: "/dashboards/blood-shipments",
      name: "blood_shipments",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_shipments/ViewBloodShipments.vue"
        ),
      roles: ["ADMIN", "AFTAP"],
    },
    {
      path: "/dashboards/blood-shipments/add",
      name: "add_blood_shipments",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_shipments/AddBloodShipments.vue"
        ),
      roles: ["ADMIN", "AFTAP"],
    },
    {
      path: "/dashboards/blood-shipments/detail/:date/:mu_name/:division",
      name: "detail_blood_shipments",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_shipments/DetailBloodShipment.vue"
        ),
      roles: ["ADMIN", "AFTAP"],
    },
    // ============ Pengolahan ================
    {
      path: "/lab/processing/blood-received",
      name: "blood_received",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_received/ViewBloodReceived.vue"
        ),
      roles: ["ADMIN", "KOMPONEN"],
    },
    {
      path: "/lab/processing/blood-results",
      name: "blood_results",
      component: () =>
        import(
          "@/view/main/lab/blood_activities/processing/ListBloodResults.vue"
        ),
      roles: ["ADMIN", "KOMPONEN"],
    },
    {
      path: "/lab/processing/blood-received/add/:mu/:date",
      name: "add_blood_received",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_received/AddBloodReceived.vue"
        ),
      roles: ["ADMIN", "KOMPONEN"],
    },
    {
      path: "/lab/processing/blood-received/print/:mu/:date",
      name: "add_blood_received",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_received/PrintBloodReceived.vue"
        ),
      roles: ["ADMIN", "KOMPONEN"],
    },
    {
      path: "/lab/processing/sieving",
      name: "sieving",
      component: () =>
        import("@/view/main/lab/blood_activities/processing/Penyileran.vue"),
      roles: ["ADMIN", "KOMPONEN"],
    },
    {
      path: "/lab/processing/weighing",
      name: "weighing",
      component: () =>
        import("@/view/main/lab/blood_activities/processing/Penimbangan.vue"),
      roles: ["ADMIN", "KOMPONEN"],
    },
    {
      path: "/lab/processing/centrifugation",
      name: "centrifugation",
      component: () =>
        import("@/view/main/lab/blood_activities/processing/Pemutaran.vue"),
      roles: ["ADMIN", "KOMPONEN"],
    },
    {
      path: "/lab/processing/component-separation",
      name: "component-separation",
      component: () =>
        import(
          "@/view/main/lab/blood_activities/processing/PemisahanKomponen.vue"
        ),
      roles: ["ADMIN", "KOMPONEN"],
    },
    {
      path: "/lab/processing/labeling-and-weighing",
      name: "labeling-and-weighing",
      component: () =>
        import(
          "@/view/main/lab/blood_activities/processing/PelabelanDanPenimbangan.vue"
        ),
      roles: ["ADMIN", "KOMPONEN"],
    },
    // ============ IMLTD ================
    {
      path: "/lab/IMLTD/blood-received",
      name: "blood_received",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_received/ViewBloodReceived.vue"
        ),
      roles: ["ADMIN", "IMLTD"],
    },
    {
      path: "/lab/IMLTD/blood-received/add/:mu/:date",
      name: "add_blood_received",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_received/AddBloodReceived.vue"
        ),
      roles: ["ADMIN", "IMLTD"],
    },
    {
      path: "/lab/IMLTD/blood-received/print/:mu/:date",
      name: "add_blood_received",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_received/PrintBloodReceived.vue"
        ),
      roles: ["ADMIN", "IMLTD"],
    },
    // KGD
    {
      path: "/lab/KGD/blood-received",
      name: "blood_received",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_received/ViewBloodReceived.vue"
        ),
      roles: ["ADMIN", "KGD"],
    },
    {
      path: "/lab/KGD/blood-received/add/:mu/:date",
      name: "add_blood_received",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_received/AddBloodReceived.vue"
        ),
      roles: ["ADMIN", "KGD"],
    },
    {
      path: "/lab/KGD/blood-received/print/:mu/:date",
      name: "add_blood_received",
      component: () =>
        import(
          "@/view/main/lab/blood_distribution/blood_received/PrintBloodReceived.vue"
        ),
      roles: ["ADMIN", "KGD"],
    },
    {
      path: "/lab/KGD/confirm-blood-type",
      name: "confirm_blood_type",
      component: () => import("@/view/main/lab/KGD/ConfirmBloodType.vue"),
      roles: ["ADMIN", "KGD"],
    },
    {
      path: "/lab/KGD/screening-antibody",
      name: "screening-antibody",
      component: () => import("@/view/main/lab/KGD/ScreeningAntiBody.vue"),
      roles: ["ADMIN", "KGD"],
    },
    // ============ Prolis ================
    {
      path: "/lab/prolis",
      name: "prolis",
      component: () => import("@/view/main/lab/prolis/ProlisView.vue"),
      roles: ["ADMIN", "PROLIS"],
    },
    {
      path: "/rekap-prolis",
      name: "prolis",
      component: () => import("@/view/main/laporan/RekapProlis.vue"),
      roles: ["ADMIN", "PROLIS"],
    },
    // ============ Pemusnahan ================
    {
      path: "/lab/pemusnahan-darah",
      name: "pemusnahan_darah",
      component: () => import("@/view/main/lab/pemusnahan/PemusnahanView.vue"),
      roles: ["ADMIN", "KOMPONEN", "IMLTD", "KGD", "PROLIS"],
    },
    {
      path: "/rekap-pemusnahan-darah",
      name: "rekap_pemusnahan_darah",
      component: () => import("@/view/main/lab/pemusnahan/RekapPemusnahan.vue"),
      roles: ["ADMIN", "KOMPONEN", "IMLTD", "KGD", "PROLIS"],
    },

    // Blood Tracking
    {
      path: "/lab/blood-tracking",
      name: "blood-tracking",
      component: () =>
        import("@/view/main/lab/blood_tracking/BloodTracking.vue"),
      roles: ["ADMIN", "KOMPONEN", "IMLTD", "KGD", "PROLIS"],
    },
    // ============ Dropping ================
    {
      path: "/lab/dropping",
      name: "dropping",
      component: () => import("@/view/main/lab/dropping/BloodbagDropping.vue"),
      roles: ["ADMIN", "PROLIS"],
    },
    {
      path: "/lab/dropping/add",
      name: "add-dropping",
      component: () =>
        import("@/view/main/lab/dropping/BloodbagDroppingForm.vue"),
      roles: ["ADMIN", "PROLIS"],
    },
    {
      path: "/lab/dropping/:id",
      name: "detail-dropping",
      component: () =>
        import("@/view/main/lab/dropping/BloodbagDroppingDetail.vue"),
      roles: ["ADMIN", "PROLIS"],
    },
    {
      path: "/lab/dropping/file/:id",
      name: "file-dropping",
      component: () =>
        import("@/view/main/lab/dropping/files/BloodDeliveryForm.vue"),
      roles: ["ADMIN", "PROLIS"],
    },
    {
      path: "/rekap-dropping",
      name: "rekap-dropping",
      component: () => import("@/view/main/laporan/RekapDropping.vue"),
      roles: ["ADMIN", "PROLIS"],
    },
    //  BLOOD BAGS
    {
      path: "/dashboards/bloodbags",
      name: "bloodbags",
      component: () => import("@/view/main/dashboards/bloodbags/Bloodbags.vue"),
    },
    {
      path: "/dashboards/bloodbag",
      name: "bloodbag",
      component: () =>
        import("@/view/main/dashboards/bloodbags/ViewBloodBag.vue"),
    },
    // PENJUALAN BLOODBAGS
    {
      path: "/dashboards/bloodbags-sellings",
      name: "bloodbags-sellings",
      component: () =>
        import("@/view/main/dashboards/bloodbagselling/BloodbagsSellings.vue"),
    },
    {
      path: "/dashboards/add-bloodbag-selling",
      name: "add-bloodbag-selling",
      component: () =>
        import(
          "@/view/main/dashboards/bloodbagselling/AddBloodbagsSelling.vue"
        ),
    },
    {
      path: "/dashboards/bloodbags-selling",
      name: "bloodbag-selling",
      component: () =>
        import("@/view/main/dashboards/bloodbagselling/ViewBloodbags.vue"),
    },
    // KELOLA RUMAH SAKIT
    {
      path: "/dashboards/hospitals",
      name: "hospitals",
      component: () =>
        import("@/view/main/dashboards/managehospital/ViewHospital.vue"),
    },
    {
      path: "/dashboards/add-hospital",
      name: "hospitals",
      component: () =>
        import("@/view/main/dashboards/managehospital/AddHospital.vue"),
    },
    {
      path: "/dashboards/edit-hospital",
      name: "hospitals",
      component: () =>
        import("@/view/main/dashboards/managehospital/EditHospital.vue"),
    },
    {
      path: "/dashboards/hospital",
      name: "hospitals",
      component: () =>
        import("@/view/main/dashboards/managehospital/ViewHospital.vue"),
    },
    {
      path: "/dashboards/patients",
      name: "listpatients",
      component: () => import("@/view/main/dashboards/patient/Patient.vue"),
    },
    {
      path: "/dashboards/add-patient",
      name: "add-patient",
      component: () => import("@/view/main/dashboards/patient/AddPatient.vue"),
    },
    {
      path: "/dashboards/edit-patient/:id",
      name: "edit-patient",
      component: () => import("@/view/main/dashboards/patient/EditPatient.vue"),
    },
    // {
    //   path: "/dashboards/patient_services",
    //   name: "list-patient-services",
    //   component: () =>
    //     import("@/view/main/dashboards/patient_service/ListPatientService.vue"),
    // },
    // {
    //   path: '/dashboards/add-patient_service',
    //   name: 'add-patient-services',
    //   component: () => import('@/view/main/dashboards/patient_service/AddAndEditDataPatientService.vue'),
    // },
    // Manage Hospitals
    {
      path: "/dashboards/pengelolaan-daftar-rs",
      name: "list-master-data-rs",
      component: () => import("@/view/main/masterData/hospitals/Hospitals.vue"),
      meta: { roles: ["ADMIN"] },
    },
    {
      path: "/dashboards/detail-rs/:id",
      name: "detail-master-data-rs",
      component: () =>
        import("@/view/main/masterData/hospitals/DetailHospital.vue"),
      meta: { roles: ["ADMIN"] },
    },
    {
      path: "/dashboards/update-rs/:id",
      name: "update-master-data-rs",
      component: () =>
        import("@/view/main/masterData/hospitals/DetailHospital.vue"),
      meta: { roles: ["ADMIN"] },
    },
    {
      path: "/dashboards/add-rs",
      name: "add-master-data-rs",
      component: () =>
        import("@/view/main/masterData/hospitals/AddHospitals.vue"),
      meta: { roles: ["ADMIN"] },
    },

    // Manage Type Inventory
    {
      path: "/master-data/permintaan-barang",
      name: "add-demand",
      component: () =>
        import(
          "@/view/main/dashboards/masterData/pengelolaanInventory/AddTypeInventory.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/tambah-daftar-barang",
      name: "add-typeinventory",
      component: () =>
        import(
          "@/view/main/dashboards/masterData/pengelolaanInventory/AddTypeInventory.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/dashboards/detail-barang",
      name: "detail-typeinventory",
      component: () =>
        import(
          "@/view/main/dashboards/masterData/pengelolaanInventory/DetailInventory.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/edit-daftar-barang/:id",
      name: "edit-typeinventory",
      component: () =>
        import(
          "@/view/main/dashboards/masterData/pengelolaanInventory/EditTypeInventory.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/dashboards/pengelolaan-staff",
      name: "pengelolaan-staff",
      component: () =>
        import("@/view/main/dashboards/masterData/staff/DaftarStaff.vue"),
      meta: { roles: ["ADMIN"] },
    },
    {
      path: "/dashboards/tambah-staff",
      name: "tambah-staff",
      component: () =>
        import("@/view/main/dashboards/masterData/staff/FormStaff.vue"),
      meta: { roles: ["ADMIN"] },
    },
    {
      path: "/dashboards/edit-staff/:id",
      name: "edit-staff",
      component: () =>
        import("@/view/main/dashboards/masterData/staff/FormStaff.vue"),
      meta: { roles: ["ADMIN"] },
    },
    // Manage Category Inventory
    {
      path: "/master-data/categoryinventorys",
      name: "categoryinventorys",
      component: () =>
        import(
          "@/view/main/dashboards/managecategoryinventory/CategoryInventory.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/barang",
      name: "barang",
      component: () =>
        import("@/view/main/masterData/inventory/DaftarBarang.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/dashboards/add-categoryinventory",
      name: "add-categoryinventory",
      component: () =>
        import(
          "@/view/main/dashboards/managecategoryinventory/AddCategoryInventory.vue"
        ),
    },
    {
      path: "/dashboards/edit-categoryinventory/:id",
      name: "edit-categoryinventory",
      component: () =>
        import(
          "@/view/main/dashboards/managecategoryinventory/EditCategoryInventory.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    // stok
    {
      path: "/master-data/laporan-stok-barang",
      name: "laporan-stok",
      component: () =>
        import("@/view/main/dashboards/masterData/sisaStok/LaporanStok.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    // Manage Vendor
    {
      path: "/master-data/vendors",
      name: "listvendors",
      component: () => import("@/view/main/dashboards/managevendor/Vendor.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/add-vendor",
      name: "add-vendor",
      component: () =>
        import("@/view/main/dashboards/managevendor/AddVendor.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/edit-vendor/:id",
      name: "edit-vendor",
      component: () =>
        import("@/view/main/dashboards/managevendor/EditVendor.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/barang-masuk",
      name: "listinventorygoods",
      component: () =>
        import("@/view/main/masterData/inventory/BarangMasuk.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/barang-masuk/tambah",
      name: "createinventorygoods",
      component: () =>
        import(
          "@/view/main/dashboards/manage_goods_logistics/incoming_goods/AddIncomingStockGoods.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/barang-masuk/edit/:id",
      name: "editinventorygoods",
      component: () =>
        import(
          "@/view/main/dashboards/manage_goods_logistics/incoming_goods/AddIncomingStockGoods.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/barang-keluar",
      name: "list_outgoing_goods",
      component: () =>
        import("@/view/main/masterData/inventory/BarangKeluar/index.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/dashboards/list-barcode",
      name: "list_barcode",
      component: () =>
        import(
          "@/view/main/dashboards/manage_goods_logistics/generate_barcode/ListBarcodes.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
      path: "/master-data/barang-keluar/tambah",
      name: "create_outgoing_bloodBag",
      component: () =>
        import("@/view/main/masterData/inventory/BarangKeluar/Form.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/barang-keluar/detail/:id",
      name: "detail_outgoing_bloodBag",
      component: () =>
        import("@/view/main/masterData/inventory/BarangKeluar/Form.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/master-data/barang-keluar/cetak-nota/:id",
      name: "cetak_outgoing_bloodBag",
      component: () =>
        import("@/view/main/masterData/inventory/BarangKeluar/Form.vue"),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },

    // BARCODE PAGE
    {
      path: "/dashboards/available-barcodes",
      name: "list_available_barcode",
      component: () =>
        import(
          "@/view/main/dashboards/barcodeAvailable/ListAvailableBarcodes.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/dashboards/list-barcode",
      name: "list_barcode",
      component: () =>
        import(
          "@/view/main/dashboards/manage_goods_logistics/generate_barcode/ListBarcodes.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/dashboards/generate-barcode",
      name: "generate_barcode",
      component: () =>
        import(
          "@/view/main/dashboards/manage_goods_logistics/generate_barcode/BarcodeGeneratorPage.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },

    {
      path: "/dashboards/create-outgoing-bloodBag/",
      name: "create_outgoing_bloodBag",
      component: () =>
        import(
          "@/view/main/dashboards/manage_goods_logistics/outgoing_goods/FormOutGoingStockBloodBag.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    {
      path: "/dashboards/update-outgoing-bloodBag/:id",
      name: "update_outgoing_bloodBag",
      component: () =>
        import(
          "@/view/main/dashboards/manage_goods_logistics/outgoing_goods/FormOutGoingStockBloodBag.vue"
        ),
      meta: { roles: ["LOGISTIK", "ADMIN"] },
    },
    // SCREENING
    {
      path: "/lab/IMLTD/list_screenings_imltd",
      name: "list_screening_imltd",
      component: () => import("@/view/main/lab/imltd/ListScreening.vue"),
      meta: { roles: ["IMLTD", "ADMIN"] },
    },
    {
      path: "/lab/IMLTD/view_non_reactive",
      name: "list_screening_imltd_non_reactive",
      component: () =>
        import("@/view/main/lab/imltd/ListScreeningNonReactiveSample.vue"),
      meta: { roles: ["IMLTD", "ADMIN"] },
    },
    {
      path: "/lab/IMLTD/view_reactive",
      name: "list_screening_imltd_reactive",
      component: () =>
        import("@/view/main/lab/imltd/ListScreeningReactiveSample.vue"),
      meta: { roles: ["IMLTD", "ADMIN"] },
    },

    // SCREENING ANTI BODY
    {
      path: "/dashboards/screening-antibodys",
      name: "listscreeningantibodys",
      component: () =>
        import(
          "@/view/main/dashboards/manage_screening_antibody/ViewScreeningAntibody.vue"
        ),
    },
    {
      path: "/dashboards/add-screening-antibody",
      name: "add-screening-antibody",
      component: () =>
        import(
          "@/view/main/dashboards/manage_screening_antibody/addData/AddScreeningAntibody.vue"
        ),
    },
    {
      path: "/dashboards/add-screeningantibody-next",
      name: "add-next-screeningantibody",
      component: () =>
        import(
          "@/view/main/dashboards/manage_screening_antibody/addData/AddNextScreeningAntibody.vue"
        ),
    },
    {
      path: "/dashboards/edit-screening-antibody/:id",
      name: "edit-screening",
      component: () =>
        import(
          "@/view/main/dashboards/manage_screening_antibody/EditScreeningAntibody.vue"
        ),
    },
    // LAB PRODUKSI
    {
      path: "/dashboards/labproductions",
      name: "listproductions",
      component: () =>
        import("@/view/main/dashboards/labproduction/LabProduction.vue"),
    },
    {
      path: "/dashboards/add-labproduction",
      name: "add-listproductions",
      component: () =>
        import("@/view/main/dashboards/labproduction/FormLabProduction.vue"),
    },
    {
      path: "/dashboards/detail-labproduction/:id",
      name: "detail-listproductions",
      component: () =>
        import("@/view/main/dashboards/labproduction/FormLabProduction.vue"),
    },
    {
      path: "/lab/print-labproduction/:barcode",
      name: "print-listproductions",
      component: () =>
        import("@/view/main/dashboards/labproduction/PrintResult.vue"),
    },
    // tracking
    {
      path: "/main/layouts/page-layouts/boxed-layout",
      name: "boxed-layout",
      component: () => import("@/view/main/layouts/page-layouts/boxed-layout"),
    },
    {
      path: "/main/layouts/page-layouts/vertical-layout",
      name: "vertical-layout",
      component: () =>
        import("@/view/main/layouts/page-layouts/vertical-layout"),
    },
    {
      path: "/main/layouts/page-layouts/horizontal-layout",
      name: "horizontal-layout",
      component: () =>
        import("@/view/main/layouts/page-layouts/horizontal-layout"),
      meta: {
        layout: "horizontal",
      },
    },
    {
      path: "/main/layouts/page-layouts/full-layout",
      name: "full-layout",
      component: () => import("@/view/main/layouts/page-layouts/full-layout"),
      meta: {
        layout: "full",
      },
    },
    // PLAYGROUND
    // {
    //   path: "/pages/playground",
    //   name: "login",
    //   component: () => import("@/view/pages/playground/Playground.vue"),
    //   meta: {
    //     layout: "full",
    //   },
    // },
    // PAGES
    {
      path: "/pages/blank-page",
      name: "blank-page",
      component: () => import("@/view/pages/blank"),
      meta: {
        title: "Blank Page",
        breadcrumb: [
          {
            text: "Pages",
          },
        ],
      },
    },
    {
      path: "/pages/authentication/login",
      name: "login",
      component: () => import("@/view/pages/authentication/login"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication/recover-password",
      name: "recover",
      component: () => import("@/view/pages/authentication/recover"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication/reset-password",
      name: "reset",
      component: () => import("@/view/pages/authentication/reset"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/welcome",
      name: "welcome",
      component: () => import("@/view/pages/lock-screen/welcome"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/deactivated",
      name: "deactivated",
      component: () => import("@/view/pages/lock-screen/deactivated"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/password-is-changed",
      name: "password-is-changed",
      component: () => import("@/view/pages/lock-screen/password"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/lock",
      name: "lock",
      component: () => import("@/view/pages/lock-screen/lock"),
      meta: {
        layout: "full",
      },
    },
    // ERROR
    {
      path: "/pages/error-404",
      name: "error-404",
      component: () => import("@/view/pages/error-page/404"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/error-403",
      name: "error-500",
      component: () => import("@/view/pages/error-page/403"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/print/:id",
      name: "print",
      component: () => import("@/view/pages/print/Print"),
      meta: {
        layout: "full",
        roles: ["ADMIN", "AFTAP", "REGISTER"],
      },
    },
    // PASIEN SERVICE DAN DROPPING
    {
      path: "/print/:id",
      name: "print",
      component: () => import("@/view/pages/print/Print"),
      meta: {
        layout: "full",
        roles: ["ADMIN", "AFTAP", "REGISTER"],
      },
    },
    // ====== LAPORAN ======
    // Rekapitulasi
    {
      path: "/rekapitulasiTransaksiCash",
      name: "rekapitulasi service",
      component: () => import("@/view/main/laporan/TransaksiCash"),
    },
    {
      path: "/rekapitulasiDarahMasuk",
      name: "rekapitulasi blood in",
      component: () => import("@/view/main/laporan/PengumpulanDarah"),
    },
    // donor
    {
      path: "/laporanDonorDarah",
      name: "laporan donor darah",
      component: () => import("@/view/main/laporan/DonorDarah"),
    },
    {
      path: "/laporanDonorDarahLengkap",
      name: "laporan donor darah lengkap",
      component: () => import("@/view/main/laporan/DonorDarahLengkap"),
    },
    {
      path: "/laporanDonorTerisiAtauTidak",
      name: "laporan donor terisi atau tidak",
      component: () => import("@/view/main/laporan/DonorTerisiAtauTidak"),
    },
    // mobil unit
    {
      path: "/rekapitulasiMUDetail",
      name: "detail rekapitulasi mu",
      component: () => import("@/view/main/laporan/MUDetail"),
    },
    // {
    //   path: "/rekapitulasiMUDetailRSUD",
    //   name: "detail rekapitulasi mu rsud",
    //   component: () => import("@/view/main/laporan/MUHospital"),
    // },
    {
      path: "/rekapitulasiMUExcel",
      name: "detail rekapitulasi excel",
      component: () => import("@/view/main/laporan/MUExcel"),
    },
    // screening
    {
      path: "/rekapitulasiScreening",
      name: "rekapitulasi screening",
      component: () => import("@/view/main/laporan/Screening"),
    },
    // laporan darah
    {
      path: "/rekapitulasiDarahKarantina",
      name: "rekapitulasi darah karantina",
      component: () => import("@/view/main/laporan/DarahKarantina"),
    },
    // finance
    {
      path: "/finance/cash-management",
      name: "cash-management",
      component: () => import("@/view/main/finance/index.vue")
    }
  ],
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));

  const userRole = userInfo?.role;

  if (
    !token &&
    to.path !== "/pages/authentication/login" &&
    to.path !== "/pages/authentication/register" &&
    to.path !== "/pages/authentication/recover-password" &&
    to.path !== "/pages/authentication/reset-password"
  )
    next("/pages/authentication/login");
  else next();

  // Check if route requires specific roles
  if (to.meta.roles) {
    if (to.meta.roles.includes(userRole) || userRole === "ADMIN") {
      next();
    } else {
      next("/pages/error-403");
    }
  } else {
    next();
  }
});

export default router;
