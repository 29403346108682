import API from '../services/services';

class PatientServices {
  getAll(params) {
    return API.get('patient/list', { params })
  }
  // getAll({ per_page, page, search }) {
  //   return API.get(`patient/list?per_page=${per_page}&search=${search}&page=${page}&order=${order}`);
  // }patient/list?per_page&search&page&order

  getById(id) {
    return API.get(`patient/detail/${id}`);
  }
  add(payload) {
    return API.post('patient/list/store', payload);
  }

  edit(payload) {
    return API.post('patient/list/update', payload);
  }

  delete(payload){
    return API.post('patient/list/delete', payload);
  }
}

export default new PatientServices();