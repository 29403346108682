<script>
import { BButton, BSpinner } from "bootstrap-vue";

export default {
  name: "BaseButton",
  components: {
    BButton,
    BSpinner,
  },
  props: ["isBusy", "loading", "text", "submitType", "variant", "style", "disabledMode"],
  methods: {
    handleClick() {
      // emit a custom event based on the submitType prop
      if (this.submitType === "submit") {
        this.$emit("submit");
      } else {
        this.$emit("click");
      }
    },
  },
};
</script>

<template>
  <b-button
    :variant="variant"
    :type="submitType === 'submit' ? 'submit' : 'reset'"
    :loading="isBusy"
    :disabled="disabledMode"
    :style="style"
    @click="handleClick"
  >
    <b-spinner v-if="isBusy" small label="Small Spinner" />
    <span v-else>{{ text }}</span>
  </b-button>
</template>

<style scoped></style>
