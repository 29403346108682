<template>
  <b-modal
    id="confirmationModal"
    centered
    hide-header
    hide-footer
  >
    <div>
      <h5
        v-if="modalType === 'accept'"
        class="mb-0"
      >
        Konfirmasi Approved Status
      </h5>
      <h5
        v-else-if="modalType === 'reject'"
        class="mb-0"
      >
        Konfirmasi Rejected Status
      </h5>
      <h5
        v-else
        class="mb-0"
      >
        Konfirmasi Edit Kuesioner Pendonor
      </h5>

      <p v-if="modalType === 'accept'">
        Apakah anda akan menyetujui Pendonor Atas Nama
        <span class="font-weight-bold">{{ currentDataPendonor.name }}</span>
        ?
      </p>
      <div v-else-if="modalType === 'reject'">
        <p>
          Apakah anda akan menolak Pendonor Atas Nama
          <span class="font-weight-bold">{{
            currentDataPendonor.name
          }}</span>
          ?
        </p>
        <div class="border-1">
          <b-form>
            <label for="textarea">Catatan:</label>
            <b-form-textarea
              id="textarea-no-resize"
              v-model="activasi.note"
              placeholder="Masukkan catatan..."
              rows="3"
              no-resize
            />
          </b-form>
        </div>
      </div>
      <p v-else-if="modalType === 'edit'">
        Apakah anda akan melakukan edit kuesioner pada Pendonor Atas Nama
        <span class="font-weight-bold">{{ currentDataPendonor.name }}</span>
        ?
      </p>

      <div class="d-flex justify-content-end flex-wrap mt-32">
        <b-button
          variant="text"
          @click="$emit('onHideModal')"
        >
          Tutup
        </b-button>
        <b-button
          v-model="isApproved"
          class="ml-16"
          :disabled="isLoading"
          :variant="
            modalType === 'accept' || modalType === 'edit'
              ? 'primary'
              : 'danger'
          "
          @click="$emit('onHandleAction')"
        >
          {{
            modalType === "accept"
              ? "Ya, Terima"
              : modalType === "reject"
                ? "Ya, Reject"
                : "Ya, Edit"
          }}
          <b-spinner
            v-if="isLoading"
            small
            label="Loading"
          />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { BModal, BButton, BSpinner } from 'bootstrap-vue'

export default {
  name: 'BaseConfirmationModalFO',
  components: { BModal, BButton, BSpinner },
  props: ['modalType', 'currentDataPendonor', 'activation', 'isLoading', 'isApproved', 'handleAction', 'hideModal'],
}

</script>
