import labproductionAPI from '../../../api/labProduction/labproductionAPI'
const labProduction = {
  namespaced: true,
  state: {
    labProductions: [],
    labProduction: {},
    isBusy: false,
    totalPages: 0,
  },
  mutations: {
    SET_LAB_PRODUCTIONS(state, data) {
      state.labProductions = data
    },
    SET_LAB_PRODUCTION_DATA(state, data) {
      state.labProduction = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getLabProductions({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

        commit('SET_IS_BUSY', true)

         // Fetch labProduction data
          return labproductionAPI.getAll(payload)
          .then(response => {
            commit('SET_LAB_PRODUCTIONS', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailLabProduction({ commit }, payload) {
      return new Promise((resolve, reject) => {
        labproductionAPI.Detail(payload).then(response => {
          commit('SET_LAB_PRODUCTION_DATA', response.data.data)
          resolve()
        })
      })
    },
    storeLabProduction({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        labproductionAPI.add(payload).then((() => {
          commit('SET_IS_BUSY', false)
          dispatch('getLabProductions')
          resolve()
        })).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    updateLabProduction({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        labproductionAPI.update(payload).then(() => {
          dispatch('getLabProductions')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    destroyLabProduction({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        labproductionAPI.delete(payload).then(() => {
          dispatch('getLabProductions')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {},
}

export default labProduction
