import incomingGoodsTypeInventoryAPI from "../../../api/incominggoodstypeinventory/incomingGoodsTypeInventoryAPI";

const incomingTypeInventory = {
  namespaced: true,
  state: {
    incomingTypeInventorys: [],
    incomingTypeInventory: {},
    isBusy: false,
    totalPages: 0,
  },
  mutations: {
    SET_INCOMING_INVENTORYS(state, data) {
      state.incomingTypeInventorys = data;
    },
    SET_INCOMING_DATA(state, data) {
      state.incomingTypeInventory = data;
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value;
    },
  },
  actions: {
    getIncomingInventorys({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve();
        }

        commit("SET_IS_BUSY", true);

        // Fetch incomingTypeInventory data
        return incomingGoodsTypeInventoryAPI
          .getAll(payload)
          .then((response) => {
            commit("SET_INCOMING_INVENTORYS", response.data.data.data);
            commit("SET_TOTAL_PAGES", response.data.data.total);
            resolve();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            commit("SET_IS_BUSY", false);
          });
      });
    },
    getDetailIncoming({ commit }, payload) {
      return new Promise((resolve, reject) => {
        incomingGoodsTypeInventoryAPI
          .getIncomingInventoryDetail(payload)
          .then((response) => {
            commit("SET_INCOMING_DATA", response.data);
            resolve(response.data.data); // Mengembalikan response.data
          })
          .catch((error) => {
            reject(error); // Tambahkan penanganan error
          });
      });
    },

    storeIncomingInventory({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve();
      }
      commit("SET_IS_BUSY", true);

      return incomingGoodsTypeInventoryAPI
        .addIncomingInventory(payload)
        .then(() => {
          commit("SET_IS_BUSY", false);
          return dispatch("getIncomingInventorys");
        })
        .catch((error) => {
          commit("SET_IS_BUSY", false);
          throw error;
        });
    },
    updateIncomingInventory({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve();
      }

      commit("SET_IS_BUSY", true);

      return incomingGoodsTypeInventoryAPI
        .updateIncomingInventory(payload)
        .then(() => {
          commit("SET_IS_BUSY", false);
          return dispatch("getIncomingInventorys");
        })
        .catch((error) => {
          commit("SET_IS_BUSY", false);
          throw error;
        });
    },
    destroyIncomingInventory({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        incomingGoodsTypeInventoryAPI
          .deleteIncomingInventory(payload)
          .then(() => {
            dispatch("getIncomingInventorys");

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {},
};

export default incomingTypeInventory;
