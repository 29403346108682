import API from "../services/services";

class ScreeningServices {
  getAll(params) {
    return API.get(`screening/list`, params );
  }

  // screeningDetail(id) {
  //   return API.get(`screening/detail/${id}`);
  // }

  barcodeScreening(payload) {
    return API.post("aftap_examination/cheking_barcode", payload);
  }

  addScreeningNonReactive(payload) {
    return API.post("screening/list/add_nonreactive", payload);
  }

  addScreeningReactive(payload) {
    return API.post("screening/list/add_reactive", payload)
  }

  updateScreening(payload) {
    return API.post("screening/list/update", payload);
  }

  // deleteScreening(payload) {
  //   return API.post("screening/list/delete", payload);
  // }

  getReport(params) {
    return API.get(`screening/show_recapitulation`, { params });
  }
}

export default new ScreeningServices();
