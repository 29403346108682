import patientAPI from "../../../api/patient/patientAPI"
const patient = {
  namespaced: true,
  state: {
    patients: [],
    patient: {},
    totalPages: 0,
    isBusy: false,
  },
  mutations: {
    SET_VATIENTS(state, data) {
      state.patients = data
    },
    SET_VATIENT_DATA(state, data) {
      state.patient = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getPatients({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

        commit('SET_IS_BUSY', true)

        // Fetch patient data
        return patientAPI.getAll(payload)
          .then(response => {
            commit('SET_VATIENTS', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailPatient({ commit }, payload) {
      return new Promise((resolve, reject) => {
        patientAPI.getById(payload)
        .then(response => {
          commit('SET_VATIENT_DATA', response.data.data)

          resolve()
        })
      })
    },
    storePatient({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        patientAPI.add(payload).then((() => {
          commit('SET_IS_BUSY', false)
          dispatch('getPatients')
          resolve()
        })).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    updatePatient({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }

      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        patientAPI.edit(payload).then(() => {
          dispatch('getPatients')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    destroyPatient({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        patientAPI.delete(payload).then(() => {
          dispatch('getPatients')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {},
}

export default patient