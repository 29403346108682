import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import themeConfig from './theme-config'
import manageVendor from './modules/master-data/manage-vendor'
import manageTypeInventory from './modules/inventorytype'
import manageCategoryInventory from './modules/inventorycategory'
import manageScreening from './modules/screening'
import incomingTypeInventory from './modules/incominggoodstypeinventory'
import manageIncomingBloodbag from './modules/incominggoodsbloodbag'
import manageIncomingReagen from './modules/incominggoodsreagen'
import manageOutgoingGood from './modules/outgoinggoods'
import patientStore from './modules/patient'
import manageScreeningAntibody from './modules/manage-screening-antibody'
import manageLabProduction from './modules/labproduction'
import manageActivasiHB from './modules/manage-hb'
import manageActivasiAftaf from './modules/manage-aftaf'
import manageActivasiDoctor from './modules/manage-doctor'
import manageKgd from './modules/manage-kgd'
import barcodeModules from './modules/manage-barcode'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    themeConfig,
    manageVendor,
    manageTypeInventory,
    manageCategoryInventory,
    manageScreening,
    incomingTypeInventory,
    manageIncomingBloodbag,
    manageIncomingReagen,
    manageOutgoingGood,
    patientStore,
    manageScreeningAntibody,
    manageLabProduction,
    manageActivasiHB,
    manageActivasiAftaf,
    manageActivasiDoctor,
    manageKgd,
    barcodeModules,
  },
  strict: process.env.DEV,
})
