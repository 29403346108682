import API from "../services/services";

class ManageAftafService {
  getAll(params) {
    return API.get("aftap_examination/list", { params });
  }

  getDetail(id) {
    return API.get(`aftap_examination/detail/${id}`);
  }
  getWaiting(params) {
    return API.get(`aftap_examination/waiting_list`, { params });
  }
  add(payload) {
    return API.post(`aftap_examination/list/store`, payload);
  }

  edit(payload) {
    return API.post("aftap_examination/list/update", payload);
  }

  delete(payload) {
    return API.post(`aftap_examination/list/delete`, payload);
  }

  checkingBarcode(payload) {
    return API.post("barcode/cheking_barcode", payload);
  }

  collectBlood(params) {
    return API.get(`aftap_examination/collected_blood`, { params });
  }
}

export default new ManageAftafService();
