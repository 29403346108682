import manageHbAPI from "../../../api/manageHB/manageHbAPI"
const activasiHB = {
  namespaced: true,
  state: {
    activasiHBs: [],
    activasiHB: {},
    totalPages: 0,
    isBusy: false,
  },
  mutations: {
    SET_HB(state, data) {
      state.activasiHBs = data
    },
    SET_HB_DATA(state, data) {
      state.activasiHB = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getActivasiHB({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }
        commit('SET_IS_BUSY', true)

        // Fetch activasiHB data
        return manageHbAPI.getAll(payload)
          .then(response => {
            commit('SET_HB', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getWaitingHB({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }
        commit('SET_IS_BUSY', true)

        return manageHbAPI.getWaiting(payload)
          .then(response => {
            commit('SET_HB', response.data.data.data)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailHB({ commit }, payload) {
      return new Promise((resolve, reject) => {
        manageHbAPI.getDetail(payload)
        .then(response => {
          commit('SET_HB_DATA', response.data.data)

          resolve()
        })
      })
    },
    storeHB({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        manageHbAPI.add(payload).then((() => {
          commit('SET_IS_BUSY', false)
          dispatch('getActivasiHB')
          resolve()
        })).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    updateHB({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }

      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        manageHbAPI.edit(payload).then(() => {
          dispatch('getActivasiHB')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    destroyHB({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        manageHbAPI.delete(payload).then(() => {
          dispatch('getActivasiHB')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {},
}

export default activasiHB