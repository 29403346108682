import API from "../services/services";

class ManageScreeningAntibodyServices {
  getAll(params) {
    return API.get(`screening_antibodi/list`, { params });
  }

  Detail(id) {
    return API.get(`screening_antibodi/detail/${id}`);
  }

  chekingBarcode(payload) {
    return API.post(`screening_antibodi/list/cheking_barcode`, payload);
  }
  add(payload) {
    return API.post(`screening_antibodi/list/store`, payload);
  }

  update(payload) {
    return API.post(`screening_antibodi/list/update`, payload);
  }
  delete(payload) {
    return API.post(`screening_antibodi/list/delete`, payload);
  }
}

export default new ManageScreeningAntibodyServices();
