import KGDServices from "../../../api/kgd/kgdAPI";

const kgd = {
  namespaced: true,
  state: {
    kgds: [],
    kgd: {},
    totalPages: 0,
    isBusy: false,
  },
  mutations: {
    SET_KGDS(state, data) {
      state.kgds = data;
    },
    SET_KGD_DATA(state, data) {
      state.kgd = data;
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value;
    },
  },
  actions: {
    getKgds({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve();
        }

        commit("SET_IS_BUSY", true);

        // Fetch kgd data
        return KGDServices.getAll(payload)
          .then((response) => {
            commit("SET_KGDS", response.data.data.data);
            commit("SET_TOTAL_PAGES", response.data.data.total);
            resolve();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            commit("SET_IS_BUSY", false);
          });
      });
    },
    getDetailKGD({ commit }, payload) {
      return new Promise((resolve, reject) => {
        KGDServices.getDetail(payload).then((response) => {
          commit("SET_KGD_DATA", response.data.data);

          resolve();
        });
      });
    },
    storeKGD({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve();
      }
      commit("SET_IS_BUSY", true);

      return KGDServices.addKgd(payload)
        .then(() => {
          commit("SET_IS_BUSY", false);
          return dispatch("getKgds");
        })
        .catch((error) => {
          commit("SET_IS_BUSY", false);
          throw error;
        });
    },
    updateKGD({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve();
      }

      commit("SET_IS_BUSY", true);

      return KGDServices.updateKgd(payload)
        .then(() => {
          commit("SET_IS_BUSY", false);
          return dispatch("getKgds");
        })
        .catch((error) => {
          commit("SET_IS_BUSY", false);
          throw error;
        });
    },
    destroyKGD({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        KGDServices.deleteKgd(payload)
          .then(() => {
            dispatch("getKgds");

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {},
};

export default kgd;
