import ManageBarcodeAPI from "../../../api/barcode/manageBarcodeAPI";

const barcodeModules = {
  namespaced: true,
  state: {
    barcodesData: [],
    barcodeDetail: {},
    totalPages: 0,
    isBusy: false,
  },
  mutations: {
    SET_BARCODES(state, data) {
      state.barcodesData = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getAllBarcodes({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

        commit('SET_IS_BUSY', true)

        // Fetch vendor data
        return ManageBarcodeAPI.getAll(payload)
          .then(response => {
            commit('SET_BARCODES', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    storeBarcodes({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return ManageBarcodeAPI.addBarcode(payload).then((() => {
        commit('SET_IS_BUSY', false)
        return dispatch('getAllBarcodes')
      }))
        .catch(error => {
          commit('SET_IS_BUSY', false)
          throw error
        })
    },
    destroyBarcodes({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        ManageBarcodeAPI.deleteBarcode(payload).then(() => {
          dispatch('getBarcodes')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}

export default barcodeModules