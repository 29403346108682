import manageAftafAPI from "../../../api/manageAftaf/manageAftafAPI"
const activasiAftaf = {
  namespaced: true,
  state: {
    activasiAftafs: [],
    activasiAftaf: {},
    totalPages: 0,
    isBusy: false,
  },
  mutations: {
    SET_AFTAF(state, data) {
      state.activasiAftafs = data
    },
    SET_AFTAF_DATA(state, data) {
      state.activasiAftaf = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getActivasiAftaf({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }
        commit('SET_IS_BUSY', true)

        // Fetch activasiAftaf data
        return manageAftafAPI.getAll(payload)
          .then(response => {
            commit('SET_AFTAF', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getWaitingAftaf({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }
        commit('SET_IS_BUSY', true)

        return manageAftafAPI.getWaiting(payload)
          .then(response => {
            commit('SET_AFTAF', response.data.data.data)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailAftaf({ commit }, payload) {
      return new Promise((resolve, reject) => {
        manageAftafAPI.getDetail(payload)
        .then(response => {
          commit('SET_AFTAF_DATA', response.data.data)

          resolve()
        })
      })
    },
    storeAftaf({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        manageAftafAPI.add(payload).then((() => {
          commit('SET_IS_BUSY', false)
          dispatch('getActivasiAftaf')
          resolve()
        })).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    updateAftaf({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }

      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        manageAftafAPI.edit(payload).then(() => {
          dispatch('getActivasiAftaf')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    destroyAftaf({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        manageAftafAPI.delete(payload).then(() => {
          dispatch('getActivasiAftaf')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {},
}

export default activasiAftaf