import API from '../services/services';


class IncomingGoodsTypeInventoryServices {
  getAll(params) {
    return API.get('incoming_goods_type_inventory/list', { params })
  }
  
  getIncomingInventoryDetail(id) {
    return API.get(`incoming_goods_type_inventory/detail/${id}`);
  }

  addIncomingInventory(payload) {
    return API.post(`incoming_goods_type_inventory/list/store`, payload);
  }

  updateIncomingInventory(payload) {
    return API.post(`incoming_goods_type_inventory/list/update`, payload);
  }
  deleteIncomingInventory(payload){
    return API.post(`incoming_goods_type_inventory/list/delete`, payload)
  }
}

export default new IncomingGoodsTypeInventoryServices();
