import API from "../services/services";

class InventoryServices {
  // getAll({ per_page, page, search }) {
  //   return API.get(`manage_type_inventory/list?per_page=${per_page}&page=${page}&search=${search}`);
  // }
  getAll(params) {
    return API.get(`manage_type_inventory/list`, {params});
  }

  getAllLists(params) {
    return API.get(`manage_type_inventory/lists`, {params})
  }

  getInventoryTypeDetail(id) {
    return API.get(`manage_type_inventory/detail/${id}`);
  }

  addTypeInventory(payload) {
    return API.post(`manage_type_inventory/list/store`, payload);
  }

  updateTypeInventory(payload) {
    return API.post(`manage_type_inventory/list/update`, payload);
  }

  deleteTypeInventory(payload) {
    return API.post(`manage_type_inventory/list/delete`, payload)
  }
}

export default new InventoryServices();