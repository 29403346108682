import incomingGoodsBloodbagAPI from "../../../api/incominggoodsbloodbag/incomingGoodsBloodbagAPI";

const incomingBloodbag = {
  namespaced: true,
  state: {
    incomingBloodbags: [],
    incomingBloodbag: {},
    isBusy: false,
    totalPages: 0,
    totalBagType: 0,
  },
  mutations: {
    SET_INCOMING_BLOODBAGS(state, data) {
      state.incomingBloodbags = data;
    },
    SET_INCOMING_DATA(state, data) {
      state.incomingBloodbag = data;
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value;
    },
    SET_TOTAL_BAG_TYPE(state, value) {
      state.totalBagType = value;
    },
  },
  actions: {
    async getIncomingBloodbags(
      { commit, state },
      { page, perPage, search, filters }
    ) {
      if (state.isBusy) {
        return Promise.resolve();
      }

      commit("SET_IS_BUSY", true);

      try {
        const response = await incomingGoodsBloodbagAPI.getAll({
          page,
          per_page: perPage,
          search,
          ...filters, // Tambahkan parameter filter ke API call
        });

        const { data_tables, total_bag_types } = response.data.data;
        const { data, total } = data_tables;

        commit("SET_INCOMING_BLOODBAGS", data);
        commit("SET_TOTAL_PAGES", total);
        commit("SET_TOTAL_BAG_TYPE", total_bag_types.total);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_BUSY", false);
      }
    },
    getDetailIncoming({ commit }, payload) {
      return new Promise((resolve, reject) => {
        incomingGoodsBloodbagAPI.getDetail(payload).then((response) => {
          commit("SET_INCOMING_DATA", response.data.data);

          resolve();
        });
      });
    },
    storeIncomingBloodbag({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve();
      }
      commit("SET_IS_BUSY", true);

      return new Promise((resolve, reject) => {
        incomingGoodsBloodbagAPI
          .add(payload)
          .then(() => {
            commit("SET_IS_BUSY", false);
            dispatch("getIncomingBloodbags");
            resolve();
          })
          .catch((error) => {
            commit("SET_IS_BUSY", false);
            reject(error);
          });
      });
    },
    updateIncomingBloodbag({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve();
      }

      commit("SET_IS_BUSY", true);

      return new Promise((resolve, reject) => {
        incomingGoodsBloodbagAPI
          .update(payload)
          .then(() => {
            commit("SET_IS_BUSY", false);
            dispatch("getIncomingBloodbags");
            resolve();
          })
          .catch((error) => {
            commit("SET_IS_BUSY", false);
            reject(error);
          });
      });
    },
    destroyIncomingBloodbag({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        incomingGoodsBloodbagAPI
          .delete(payload)
          .then(() => {
            dispatch("getIncomingBloodbags");

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {},
};

export default incomingBloodbag;
