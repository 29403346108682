import API from '../services/services';

class IncomingGoodsReagenServices {
  getAll(params) {
    return API.get('incoming_goods_reagen/list', { params })
  }
  
  getDetail(id) {
    return API.get(`incoming_goods_reagen/detail/${id}`);
  }

  add(payload) {
    return API.post(`incoming_goods_reagen/list/store`, payload);
  }

  update(payload) {
    return API.post(`incoming_goods_reagen/list/update`, payload);
  }
  delete(payload){
    return API.post(`incoming_goods_reagen/list/delete`, payload)
  }
}

export default new IncomingGoodsReagenServices();
