import API from '../services/services';

class VendorServices {
  getAll(params) {
    return API.get('manage_vendor/list', { params })
  }
  // getAll({ per_page, page, search }) {
  //   return API.get(`manage_vendor/list?per_page=${per_page}&page=${page}&search=${search}`);
  // }

  getVendorById(id) {
    return API.get(`manage_vendor/detail/${id}`);
  }
  add(payload) {
    return API.post('manage_vendor/list/store', payload);
  }

  edit(payload) {
    return API.post('manage_vendor/list/update', payload);
  }

  delete(payload){
    return API.post('manage_vendor/list/delete', payload);
  }
}

export default new VendorServices();