import manageDoctorAPI from "../../../api/managedoctor/manageDoctorAPI"
const activasiDoctor = {
  namespaced: true,
  state: {
    activasiDoctors: [],
    activasiDoctor: {},
    totalPages: 0,
    isBusy: false,
  },
  mutations: {
    SET_DOCTOR(state, data) {
      state.activasiDoctors = data
    },
    SET_DOCTOR_DATA(state, data) {
      state.activasiDoctor = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getActivasiDoctor({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }
        commit('SET_IS_BUSY', true)

        // Fetch activasiDoctor data
        return manageDoctorAPI.getAll(payload)
          .then(response => {
            commit('SET_DOCTOR', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getWaitingDoctor({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }
        commit('SET_IS_BUSY', true)

        return manageDoctorAPI.getWaiting(payload)
          .then(response => {
            commit('SET_DOCTOR', response.data.data.data)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailDoctor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        manageDoctorAPI.getDetail(payload)
        .then(response => {
          commit('SET_DOCTOR_DATA', response.data.data)

          resolve()
        })
      })
    },
    storeDoctor({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        manageDoctorAPI.add(payload).then((() => {
          commit('SET_IS_BUSY', false)
          dispatch('getActivasiDoctor')
          resolve()
        })).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    updateDoctor({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }

      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        manageDoctorAPI.edit(payload).then(() => {
          dispatch('getActivasiDoctor')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    destroyDoctor({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        manageDoctorAPI.delete(payload).then(() => {
          dispatch('getActivasiDoctor')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {},
}

export default activasiDoctor