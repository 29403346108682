import { camelCase, upperFirst } from 'lodash';

const registerBaseComponent = vm => {
  try {
    // Require base component context
    const requireComponent = require.context('../components/base', true, /Base[A-Z]\w+\.(vue|js)$/);

    requireComponent.keys().forEach(filePath => {
      // Get component config
      const componentConfig = requireComponent(filePath);

      // Get Filename from the filepath
      const fileName = filePath.split('/').slice(-1)[0];

      // Remove file extension and convert component name to pascal case
      const componentName = upperFirst(camelCase(fileName.replace(/\.\w+$/, '')));

      // Register Component Globally
      vm.component(componentName, componentConfig.default || componentConfig);
    })
  } catch (error) {
    console.error('Base Component Registration Failed');
    console.log(error);
  }
};

export default registerBaseComponent;