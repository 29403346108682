import Vue from "vue";
import VueCompositionAPI, { createApp, h } from "@vue/composition-api";

import store from "./store";
import router from "./router";
import App from "./App.vue";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import L from "leaflet";
import * as VueGoogleMaps from "vue2-google-maps";
import registerBaseComponent from "@/helpers/registerBaseComponent";

// Register Component
registerBaseComponent(Vue);

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Vue Page Transition
import VuePageTransition from "vue-page-transition";

// Bootstrap
import {
  BootstrapVueIcons,
  PopoverPlugin,
  ToastPlugin,
  ModalPlugin,
  SidebarPlugin,
  TooltipPlugin,
  TabsPlugin,
} from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Highlightjs
import VueHighlightJS from "vue-highlightjs";
import "highlight.js/styles/mono-blue.css";

// Apexcharts
import VueApexCharts from "vue-apexcharts";

import "vue-select/dist/vue-select.css";

// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";

// Vue Signature
import VueSignaturePad from "vue-signature-pad";

// Bootstrap Plugin
Vue.use(TabsPlugin);
Vue.use(TooltipPlugin);
Vue.use(SidebarPlugin);
Vue.use(PopoverPlugin);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVueIcons);

// Vue Signature
Vue.use(VueSignaturePad);

// Vue Page Transition
Vue.use(VuePageTransition);

// Highlight
Vue.use(VueHighlightJS);

// Apexchart
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

// Style
require("@/assets/icons/iconly/index.min.css");
require("@/assets/icons/remix-icon/index.min.css");
require("@/assets/scss/app.scss");

// Composition Api
Vue.use(VueCompositionAPI);

// Google Maps
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAP_API_KEY,
    libraries: "places",
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
