import outgoingGoodsAPI from "../../../api/outgoinggoods/outgoingGoodsAPI";

const outgoingGood = {
  namespaced: true,
  state: {
    outgoingGoods: [],
    outgoingGoodDetail: {},
    isBusy: false,
    totalPages: 0,
  },
  mutations: {
    SET_OUTGOING_GOODS(state, data) {
      state.outgoingGoods = data;
    },
    SET_OUTGOING_DATA(state, data) {
      state.outgoingGoodDetail = data;
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value;
    },
    SET_TOTAL_ITEMS(state, total) {
      state.totalItems = total;
    },
  },
  actions: {
    getOutgoingGoods({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve();
        }
        commit("SET_IS_BUSY", true);
        return new Promise((resolve, reject) => {
          outgoingGoodsAPI
            .getAll(payload)
            .then((response) => {
              commit("SET_OUTGOING_GOODS", response.data.data.data);
              commit("SET_TOTAL_PAGES", response.data.data.total);
              commit("SET_TOTAL_ITEM", response.data.data.total);
              resolve();
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              commit("SET_IS_BUSY", false);
            });
        });
      });
    },
    getDetailOutgoingGoods({ commit }, payload) {
      return new Promise((resolve, reject) => {
        outgoingGoodsAPI.getDetailOutgoingGoods(payload).then((response) => {
          commit("SET_OUTGOING_DATA", response.data.data);

          resolve();
        });
      });
    },
    storeOutgoingGoods({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve();
      }
      commit("SET_IS_BUSY", true);

      return outgoingGoodsAPI
        .add(payload)
        .then(() => {
          commit("SET_IS_BUSY", false);
          return dispatch("getOutgoingGoods");
        })
        .catch((error) => {
          commit("SET_IS_BUSY", false);
          throw error;
        });
    },
    updateOutgoingGoods({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve();
      }

      commit("SET_IS_BUSY", true);

      return outgoingGoodsAPI
        .edit(payload)
        .then(() => {
          commit("SET_IS_BUSY", false);
          return dispatch("getOutgoingGoods");
        })
        .catch((error) => {
          commit("SET_IS_BUSY", false);
          throw error;
        });
    },
    destroyVendor({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        outgoingGoodsAPI
          .delete(payload)
          .then(() => {
            dispatch("getOutgoingGoods");

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {},
};

export default outgoingGood;
