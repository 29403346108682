import ManageVendorAPI from '../../../../api/managevendor/manageVendorAPI'

const vendor = {
  namespaced: true,
  state: {
    vendors: [],
    vendor: {},
    totalPages: 0,
    isBusy: false,
  },
  mutations: {
    SET_VENDORS(state, data) {
      state.vendors = data
    },
    SET_VENDOR_DATA(state, data) {
      state.vendor = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getVendors({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

        commit('SET_IS_BUSY', true)

        // Fetch vendor data
        return ManageVendorAPI.getAll(payload)
          .then(response => {
            commit('SET_VENDORS', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailVendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ManageVendorAPI.getVendorById(payload)
          .then(response => {
            commit('SET_VENDOR_DATA', response.data.data)

            resolve()
          })
      })
    },
    storeVendor({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return ManageVendorAPI.add(payload).then((() => {
        commit('SET_IS_BUSY', false)
        return dispatch('getVendors')
      }))
        .catch(error => {
          commit('SET_IS_BUSY', false)
          throw error
        })

      // return new Promise((resolve, reject) => {
      //   ManageVendorAPI.add(payload).then((() => {
      //     commit('SET_IS_BUSY', false)
      //     return dispatch('getVendors')
      //   }))
      //     .then(() => {
      //       resolve()
      //     })
      //     .catch(error => {
      //       commit('SET_IS_BUSY', false)
      //       reject(error)
      //     })
      // })
    },
    updateVendor({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }

      commit('SET_IS_BUSY', true)

      return ManageVendorAPI.edit(payload)
        .then(() => {
          commit('SET_IS_BUSY', false)
          return dispatch('getVendors')
        })
        .catch(error => {
          commit('SET_IS_BUSY', false)
          throw error
        })
    },
    destroyVendor({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        ManageVendorAPI.delete(payload).then(() => {
          dispatch('getVendors')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {},
}

export default vendor
