import API from "../services/services";

class KGDServices {
  getAll(params) {
    return API.get("kgd/list", { params });
  }

  getDetail(id) {
    return API.get(`kgd/detail/${id}`);
  }

  checkingBarcode(payload) {
    return API.post("kgd/cheking_barcode", payload);
  }

  addKgd(payload) {
    return API.post("kgd/list/store", payload);
  }

  updateKgd(payload) {
    return API.post("kgd/list/update", payload);
  }

  deleteKgd(payload) {
    return API.post("kgd/list/delete", payload);
  }
}

export default new KGDServices();
