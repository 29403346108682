import incomingGoodsReagenAPI from '../../../api/incominggoodsreagen/incomingGoodsReagenAPI'
const incomingReagen = {
  namespaced: true,
  state: {
    incomingReagens: [],
    incomingReagen: {},
    isBusy: false,
    totalPagesReagen: 0,
  },
  mutations: {
    SET_INCOMING_REAGENS(state, data) {
      state.incomingReagens = data
    },
    SET_INCOMING_DATA(state, data) {
      state.incomingReagen = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPagesReagen = value
    },
  },
  actions: {
    getIncomingReagens({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

       commit('SET_IS_BUSY', true)

        // Fetch incomingReagen data
        return incomingGoodsReagenAPI.getAll(payload)
          .then(response => {
            commit('SET_INCOMING_REAGENS', response.data.data.data_tables.data)
            commit('SET_TOTAL_PAGES', response.data.data.total_reagen)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailIncoming({ commit }, payload) {
      return new Promise((resolve, reject) => {
        incomingGoodsReagenAPI.getDetail(payload)
        .then(response => {
          commit('SET_INCOMING_DATA', response.data.data)

          resolve()
        })
      })
    },
    storeIncomingReagen({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)
      
      return new Promise((resolve, reject) => {
        incomingGoodsReagenAPI.add(payload).then((() => {
          commit('SET_IS_BUSY', false)
          dispatch('getIncomingReagens')
          resolve()
        })).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    updateIncomingReagen({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }

      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        incomingGoodsReagenAPI.update(payload).then(() => {
          commit('SET_IS_BUSY', false)
          dispatch('getIncomingReagens')
          resolve()
        }).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    destroyIncomingReagen({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        incomingGoodsReagenAPI.delete(payload).then(() => {
          dispatch('getIncomingReagens')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {},
}

export default incomingReagen
