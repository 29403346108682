import API from '../services/services';

class CategoryInventoryServices {
  getAll(params) {
    return API.get('manage_category_inventory/list', { params })
  }
  
  getCategoryInventoryDetail(id) {
    return API.get(`manage_category_inventory/detail/${id}`);
  }

  addCategoryInventory(payload) {
    return API.post(`manage_category_inventory/list/store`, payload);
  }

  updateCategoryInventory(payload) {
    return API.post(`manage_category_inventory/list/update`, payload);
  }
  deleteCategoryInventory(payload){
    return API.post(`manage_category_inventory/list/delete`, payload)
  }
}

export default new CategoryInventoryServices();
