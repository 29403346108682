import managescreeningantibodyAPI from '../../../api/managescreeningantibody/managescreeningantibodyAPI'
const screeningAntibody = {
  namespaced: true,
  state: {
    screeningAntibodys: [],
    screeningAntibody: {},
    isBusy: false,
    totalPages: 0,
  },
  mutations: {
    SET_SCREENING_ANTIBODYS(state, data) {
      state.screeningAntibodys = data
    },
    SET_SCREENING_DATA(state, data) {
      state.screeningAntibody = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getScreeningAntibodys({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

        commit('SET_IS_BUSY', true)

         // Fetch screeningAntibody data
          return managescreeningantibodyAPI.getAll(payload)
          .then(response => {
            commit('SET_SCREENING_ANTIBODYS', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailScreeningAntibody({ commit }, payload) {
      return new Promise((resolve, reject) => {
        managescreeningantibodyAPI.Detail(payload).then(response => {
          commit('SET_SCREENING_DATA', response.data.data)
          resolve()
        })
      })
    },
    chekingScreeningAntibody({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        managescreeningantibodyAPI.chekingBarcode(payload).then((() => {
          commit('SET_IS_BUSY', false)
          dispatch('getScreeningAntibodys')
          resolve()
        })).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    storeScreeningAntibody({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        managescreeningantibodyAPI.add(payload).then((() => {
          commit('SET_IS_BUSY', false)
          dispatch('getScreeningAntibodys')
          resolve()
        })).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    updateScreeningAntibody({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        managescreeningantibodyAPI.update(payload).then(() => {
          dispatch('getScreeningAntibodys')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    destroyScreeningAntibody({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        managescreeningantibodyAPI.delete(payload).then(() => {
          dispatch('getScreeningAntibodys')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {},
}

export default screeningAntibody
