import API from '../services/services'

class ManageHBService {
  getAll(params) {
    return API.get('hb_examination/list', { params })
  }
  getDetail(id) {
    return API.get(`hb_examination/detail/${id}`);
  }
  getWaiting(params) {
      return API.get(`hb_examination/waiting_list`, { params })
    }
  add(payload) {
    return API.post('hb_examination/list/store', payload)
  }

  edit(payload) {
    return API.post('hb_examination/list/update', payload)
  }

  delete(payload){
    return API.post('hb_examination/list/delete', payload)
  }
}

export default new ManageHBService()
