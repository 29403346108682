import axios from "axios";
import API from "../services/services";

const REGISTER_API = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL1,
});

class PendonorDataService {
  createReservation({ user_id, blood_van_id, hospital_id, date, hour }, token) {
    const payload = { user_id, blood_van_id, hospital_id, date, hour };
    return REGISTER_API.post("mobile/registration/store", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  getAll(params) {
    return API.get("manage_user/list_pengguna", { params });
  }

  get(id) {
    return API.get(`manage_user/list_pengguna/detail/${id}`);
  }

  add(payload) {
    return REGISTER_API.post("mobile/auth/register", payload);
  }

  update({ id, name, email, birth_date, address, phone, blood_id }) {
    return API.post("manage_user/list_pengguna/update", {
      id,
      name,
      email,
      birth_date,
      address,
      phone,
      blood_id,
    });
  }

  nonactive({ id }) {
    return API.post("manage_user/list_pengguna/nonactive", {
      id,
    });
  }

  active({ id }) {
    return API.post("manage_user/list_pengguna/active", {
      id,
    });
  }

  getListProvinces() {
    return REGISTER_API.get("/provinces");
  }

  getListCities(params) {
    return REGISTER_API.get(`/city/${params}`);
  }

  getListVillages(villageId) {
    return REGISTER_API.get(`/villages/${villageId}`);
  }

  getListDistricts(districtId) {
    return REGISTER_API.get(`/districts/${districtId}`);
  }

  delete(id) {
    return API.post("manage_user/list_pengguna/delete", id);
  }
}

export default new PendonorDataService();
