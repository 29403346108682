import manageInventoryAPI from '../../../api/manageinventory/manageInventoryAPI'

const typeinventory = {
  namespaced: true,
  state: {
    allListsOfTypeInventory: [],
    typeInventorys: [],
    typeInventory: {},
    isBusy: false,
    totalPages: 0,
  },
  mutations: {
    // FOR DROPDOWN PURPOSES
    SET_ALL_TYPE_INVENTORYS(state, data) {
      state.allListsOfTypeInventory = data
    },
    // END DROPDOWN PURPOSES
    SET_TYPE_INVENTORYS(state, data) {
      state.typeInventorys = data
    },
    SET_TYPE_DATA(state, data) {
      state.typeInventory = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getAllTypeInventorys({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

        commit('SET_IS_BUSY', true)

        // Fetch type inventory data
        return manageInventoryAPI
          .getAllLists(payload)
          .then(response => {
            commit('SET_ALL_TYPE_INVENTORYS', response.data.data)
          })
          .then(() => {
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getTypeInventorys({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

        commit('SET_IS_BUSY', true)

        // Fetch type inventory data
        return manageInventoryAPI
          .getAll(payload)
          .then(response => {
            commit('SET_TYPE_INVENTORYS', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
          })
          .then(() => {
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailType({ commit }, payload) {
      return new Promise((resolve, reject) => {
        manageInventoryAPI.getInventoryTypeDetail(payload).then(response => {
          commit('SET_TYPE_DATA', response.data.data)

          resolve()
        })
      })
    },
    storeType({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return manageInventoryAPI
        .addTypeInventory(payload)
        .then(() => {
          commit('SET_IS_BUSY', false)
          return dispatch('getTypeInventorys')
        })
        .catch(error => {
          commit('SET_IS_BUSY', false)
          console.error(error)
        })
    },
    updateType({ commit, dispatch }, payload) {
      commit('SET_IS_BUSY', true)

      return manageInventoryAPI
        .updateTypeInventory(payload)
        .then(() => {
          commit('SET_IS_BUSY', false)
          return dispatch('getTypeInventorys')
        })
        .catch(error => {
          commit('SET_IS_BUSY', false)
          console.error(error)
          throw error
        })
    },
    destroyType({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        manageInventoryAPI
          .deleteTypeInventory(payload)
          .then(() => {
            dispatch('getTypeInventorys')

            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  getters: {},
}

export default typeinventory
