import API from "../services/services";

class OutgoingGoodServices {
  getAll(params) {
    return API.get(`outgoing_goods_type_inventory/list`, { params });
  }

  getDetailOutgoingGoods(id) {
    return API.get(`outgoing_goods_type_inventory/detail/${id}`);
  }

  add(payload) {
    return API.post(`outgoing_goods_type_inventory/list/store`, payload);
  }

  edit(payload) {
    return API.post(`outgoing_goods_type_inventory/list/update`, payload);
  }

  delete(payload) {
    return API.post("outgoing_goods_type_inventory/list/delete", payload);
  }

  getAllNota(params) {
    return API.get(`nota/list`, { params });
  }
  detailNota(id) {
    return API.get(`nota/detail/${id}`);
  }
  addNota(payload) {
    return API.post(`nota/create`, payload);
  }
  addReturnNota(payload) {
    return API.post(`return_goods_type_inventory/list/store`, payload);
  }
}

export default new OutgoingGoodServices();
