import screeningAPI from '../../../api/screening/screeningAPI'

const screening = {
  namespaced: true,
  state: {
    screenings: [],
    screening: {},
    isBusy: false,
    totalPages: 0,
  },
  mutations: {
    SET_SCREENINGS(state, data) {
      state.screenings = data
    },
    SET_SCREENING_DATA(state, data) {
      state.screening = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getScreenings({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

        commit('SET_IS_BUSY', true)

        // Fetch screening data
        return screeningAPI.getAll(payload)
          .then(response => {
            commit('SET_SCREENINGS', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailScreening({ commit }, payload) {
      return new Promise((resolve, reject) => {
        screeningAPI.screeningDetail(payload).then(response => {
          commit('SET_SCREENING_DATA', response.data.data)
          resolve()
        })
      })
    },
    chekingScreening({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return new Promise((resolve, reject) => {
        screeningAPI.barcodeScreening(payload).then((() => {
          commit('SET_IS_BUSY', false)
          dispatch('getScreenings')
          resolve()
        })).catch(error => {
          commit('SET_IS_BUSY', false)
          reject(error)
        })
      })
    },
    storeScreening({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return screeningAPI.addScreening(payload).then((() => {
        commit('SET_IS_BUSY', false)
        return dispatch('getScreenings')
      })).catch(error => {
        commit('SET_IS_BUSY', false)
        throw error
      })
    },
    updateScreening({ dispatch, state, commit }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return screeningAPI.updateScreening(payload).then(() => {
        commit('SET_IS_BUSY', false)
        return dispatch('getScreenings')
      }).catch(error => {
        commit('SET_IS_BUSY', false)
        throw error
      })
    },
    destroyScreening({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        screeningAPI.deleteScreening(payload).then(() => {
          dispatch('getScreenings')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {},
}

export default screening
