import API from "../services/services";

class ManageLabProductionServices {
  getAll(params) {
    return API.get(`lab_production/list`, { params });
  }

  Detail(id) {
    return API.get(`lab_production/detail/${id}`);
  }
  add(payload) {
    return API.post(`lab_production/list/store`, payload);
  }

  update(payload) {
    return API.post(`lab_production/list/update`, payload);
  }
  delete(payload) {
    return API.post(`lab_production/list/delete`, payload);
  }
  checkBarcode(payload) {
    return API.post(`lab_production/list/cheking_barcode`, payload);
  }
  countProduction() {
    return API.get("lab_production/count_lab_production");
  }
}

export default new ManageLabProductionServices();
