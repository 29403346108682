import API from '../services/services'

class ManageBarcodeService {
  getAll(params) {
    return API.get('barcode/list', { params })
  }

  getAllAvailableBarcodes(params) {
    return API.get('barcode/barcode_available', { params })
  }

  getDetail(id) {
    return API.get(`kgd/detail/${id}`)
  }

  addBarcode(payload) {
    return API.post('barcode/list/store', payload)
  }

  deleteBarcode(payload) {
    return API.post('barcode/list/delete', payload)
  }
}

export default new ManageBarcodeService()
