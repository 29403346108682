import axios from "axios";

const token = localStorage.getItem('token');

const API = axios.create({
    baseURL: process.env.VUE_APP_VARIABLES,
    headers: {
        Authorization: `Bearer ${token}`
    }
});

export default API
