import API from "../services/services";

class IncomingGoodsBloodbagServices {
  getAll(params) {
    return API.get("incoming_goods_blood_bag/list", { params });
  }

  getDetail(id) {
    return API.get(`incoming_goods_blood_bag/detail/${id}`);
  }

  add(payload) {
    return API.post(`incoming_goods_blood_bag/list/store`, payload);
  }

  update(payload) {
    return API.post(`incoming_goods_blood_bag/list/update`, payload);
  }
  delete(payload) {
    return API.post(`incoming_goods_blood_bag/list/delete`, payload);
  }
}

export default new IncomingGoodsBloodbagServices();
