<template>
  <!--
    Usage:
    <BaseModal>
      <template #header="{ close }">
        <h5 class="mb-0">Modal Title</h5>
        <b-button variant="text" @click="close()">
          Close
        </b-button>
      </template>
      <div>Modal content</div>
    </BaseModal>
  -->
  <b-modal
    id="base-modal"
    hide-backdrop
    content-class="shadow"
    hide-footer
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">
        <slot name="header" :close="close" />
      </h5>

      <b-button
        variant="text"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
        @click="close()"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        />
      </b-button>
    </template>

    <div>
      <slot />
    </div>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
}
</script>
