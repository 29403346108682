import manageCategoryInventoryAPI from '../../../api/managecategoryinventory/manageCategoryInventoryAPI'

const categoryInventory = {
  namespaced: true,
  state: {
    categoryInventorys: [],
    categoryInventory: {},
    isBusy: false,
    totalPages: 0,
  },
  mutations: {
    SET_CATEGORY_INVENTORYS(state, data) {
      state.categoryInventorys = data
    },
    SET_CATEGORY_DATA(state, data) {
      state.categoryInventory = data
    },
    SET_IS_BUSY(state, value) {
      state.isBusy = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
  },
  actions: {
    getCategoryInventorys({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.isBusy) {
          return Promise.resolve()
        }

        commit('SET_IS_BUSY', true)

        // Fetch categoryInventory data
        return manageCategoryInventoryAPI.getAll(payload)
          .then(response => {
            commit('SET_CATEGORY_INVENTORYS', response.data.data.data)
            commit('SET_TOTAL_PAGES', response.data.data.total)
            resolve()
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            commit('SET_IS_BUSY', false)
          })
      })
    },
    getDetailCategory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        manageCategoryInventoryAPI.getCategoryInventoryDetail(payload)
          .then(response => {
            commit('SET_CATEGORY_DATA', response.data.data)

            resolve()
          })
      })
    },
    storeCategory({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }
      commit('SET_IS_BUSY', true)

      return manageCategoryInventoryAPI.addCategoryInventory(payload).then((() => {
        commit('SET_IS_BUSY', false)
        return dispatch('getCategoryInventorys')
      }))
        .catch(error => {
          commit('SET_IS_BUSY', false)
          throw error
        })

      // return new Promise((resolve, reject) => {
      //   manageCategoryInventoryAPI.addCategoryInventory(payload).then((() => {
      //     commit('SET_IS_BUSY', false)
      //     return dispatch('getCategoryInventorys')
      //   }))
      //     .then(() => {
      //       resolve()
      //     })
      //     .catch(error => {
      //       commit('SET_IS_BUSY', false)
      //       reject(error)
      //     })
      // })
    },
    updateCategory({ commit, state, dispatch }, payload) {
      if (state.isBusy) {
        return Promise.resolve()
      }

      commit('SET_IS_BUSY', true)

      return manageCategoryInventoryAPI.updateCategoryInventory(payload).then(() => {
        commit('SET_IS_BUSY', false)
        return dispatch('getCategoryInventorys')
      })
        .catch(error => {
          commit('SET_IS_BUSY', false)
          throw error
        })
    },
    destroyCategory({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        manageCategoryInventoryAPI.deleteCategoryInventory(payload).then(() => {
          dispatch('getCategoryInventorys')
          resolve()
        })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  getters: {},
}

export default categoryInventory
